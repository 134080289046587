import { Controller } from "@hotwired/stimulus";

export default class NotificationsController extends Controller {
    static values = {
        open: Boolean,
        total: String,
        requestUrl: String,
        alertsUrl: String
    }

    connect() {
        this.toggleTransitionHide();
    }

    toggle() {
        this.openValue = !this.openValue
        if (this.isOpen()) {
            this.markAllAsRead();
        }
        this.toggleTransition()
    }

    toggleTransition() {
        if (this.isOpen()) {
            this.toggleTransitionShow();
        } else {
            this.toggleTransitionHide();
        }
    }

    toggleTransitionShow() {
        $(".box-alerts__messages").fadeIn("slow");
        $(".box-alerts__list__item").slideDown("slow");
    }

    toggleTransitionHide() {
        $(".box-alerts__messages").fadeOut("slow");
        $(".box-alerts__list__item").slideUp("slow");
    }

    isOpen() {
        return this.openValue;
    }

    markAllAsRead() {
        const _requestUrlValue = this.requestUrlValue;
        $.ajax({
            url: `${_requestUrlValue}`,
            method: 'PATCH',
            xhrFields: {
                withCredentials: true
            },
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });
        this.clearCount();
    }

    clearCount() {
        this.totalValue = "0";
        $("#box-alerts__count").fadeOut("slow");
    }
}
