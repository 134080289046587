/*

USAGE
====================
.cpn-carousel{ "data-controller": "carousel", ["data-item": ""], ["data-navtype": ""] } }
  
  .cpn-item[.custom-class]{ ["data-thumb": ""], ["thumb-video": "true"] }


DATA PARAMS
====================

.cpn-carousel (container element)

    data-item: (optional) (default value: "cpn-item"):
      - Carousel items selector class.

    data-navtype: (optional) (default value: "bullets"):
      - "bullets"
        Pages rendered as bullets.
      - "thumbs"
        Pages rendered as thumbs. Requires items to have data-thumb params.
      - "arrows"
        No bullets or thumbs rendered, only previous and next buttons.

    data-rotate: (optional) (default value: undefined):
      - Integer
        Frequency of page advance in milisecondas

.cpn-item (children elements)

    data-thumb: (optional) (default value: "cpn-item"):
      - url
        The thumb image URL for the item

    data-thumb-video: (optional):
      - "true"
        Ids the thumb as a video thumb adding the overlaying play icon


*/

import { Controller } from "@hotwired/stimulus"

export default class CarouselController extends Controller {

  initialize() {
    this.cont = $(this.element);

    if(this.cont.data("item") == undefined){ this.cont.data("item", "cpn-item") }
    this.itemClass = this.cont.data("item")
    this.cont.data("rotate")  != undefined ? this.rotateMs  = this.cont.data("rotate")  : this.rotateMs  = undefined

    this.navtype = "bullets";
    if(this.cont.hasClass("cpn-carousel-thumbs") ){ this.navtype = "thumbs"; }
    if(this.cont.hasClass("cpn-carousel-arrows") ){ this.navtype = "arrows"; }

    this.view     = this.cont.find(".cpn-carousel-viewport");
    this.wrap     = this.view.find(".cpn-carousel-wrapper");
    this.navCont  = this.cont.find(".cpn-carousel-nav");
    this.pageCont = this.navCont.find(".cpn-carousel-pages");
    this.btPrev   = this.navCont.find(".cpn-carousel-prev");
    this.btNext   = this.navCont.find(".cpn-carousel-next");
    this.curPage  = 0;

    if(this.rotateMs != undefined && this.wrap.children("."+this.itemClass).length > 1){
      this.cont.attr("data-action", "resize@window->carousel#refresh mouseover->carousel#unbindRotate mouseout->carousel#bindRotate");
      this.bindRotate();
    }
    this.refresh();
  }

  connect() {}

  refresh() {
    this.items = this.wrap.children("."+this.itemClass);
    if(this.items.length < 1) return

    this.lastItem = this.items.eq(this.items.length - 1);
    this.items.css("width","").css("width",this.lastItem.innerWidth());
    this.viewWidth = this.view.innerWidth();
    this.wrapWidth = this.lastItem.position().left + this.lastItem.innerWidth();

    if( (this.wrapWidth/this.viewWidth) % 1 < 0.1 )
      this.pageLength = Math.floor(this.wrapWidth/this.viewWidth);
    else
      this.pageLength = Math.ceil(this.wrapWidth/this.viewWidth);
    

    if(this.pageLength > 1){
      this.buildNav();
      this.navCont.show();
    }else{
      this.navCont.hide();
    }

    this.observe();
  }

  buildNav() {
    this.pageCont.children().remove();
    var pageButtons = ""

    for(var p=0; p<this.pageLength ; p++){
      var item = this.items.eq(p);
      var thumbUrl = "";
      var thumbIcon = "";
      var thumbImage = "";

      if(this.navtype == "thumbs"){
        if(item.attr("data-thumb-video") == "true"){ thumbIcon = "<i class='fa fa-play'></i>" };
        var thumbImage = `<img class='thumb' src='${item.attr("data-thumb")}' />`
      }

      var pageButton = `<button class='cpn-carousel-page' data-action='click->carousel#setPage' data-page=${p}> ${thumbImage} ${thumbIcon} </button>`;
      pageButtons += pageButton;
    }

    $(pageButtons).appendTo(this.pageCont);
    this.pages = this.pageCont.find(".cpn-carousel-page");

    this.goToPage(0);
  }

  setPage(){
    this.goToPage(event.currentTarget.dataset.page)
  }

  goToPage(pageNum) {
    if(pageNum == "prev"){ pageNum = this.curPage - 1 }
    if(pageNum == "next"){ pageNum = this.curPage + 1 }
    pageNum = parseInt(pageNum);
    if(pageNum >= this.pageLength) pageNum = 0;
    if(pageNum < 0) pageNum = this.pageLength - 1;
    this.curPage = pageNum;

    if(this.pages != undefined) this.pages.removeClass('active').eq(pageNum).addClass('active');
    $(this.wrap).css("transform", "translateX(-"+ (pageNum) * this.viewWidth +"px)");
  }


  // PROGRESS BAR METHODS
  // ==========================================================================================
  pbarEmpty(){
    this.pbar.css({
      transition: "initial",
      width: "0"
    });
  }

  pbarFill(){
    this.pbar.css({
      transition: `width ${this.rotateMs}ms ease-in-out`,
      width: "100%"
    });
  }

  // AUTO ROTATE METHODS
  // ==========================================================================================
  bindRotate(){
    if(this.pbar == undefined) {
      this.bar  = this.cont.find('span.pbar')
      this.pbar = this.bar.find('i')
    }

    this.pbar.on('transitionend', () => {
      this.pbarEmpty()
      setTimeout(() => {
        this.curPage++;
        this.goToPage(this.curPage);
        this.pbarFill();
      })
    })
    
    this.pbarFill();
  }

  unbindRotate(){
    this.pbar.off('transtionend')
    this.pbarEmpty();
  }

  // OBSERVE - LISTENS TO CHANGES IN THE DOM INSIDE THE CAROUSEL TO TRIGGER REFRESH
  // ==========================================================================================
  observe(){
    if(this.observer == undefined){
      var mobserver = window.MutationObserver || window.WebKitMutationObserver;
      this.observer = new mobserver(observerTrigger);
    }
    
    this.observer.observe(this.element, { childList: true, characterData: false, attributes: false, subtree: true } );
    
    var ctrl = this;
    function observerTrigger(){
      ctrl.observer.disconnect();
      ctrl.refresh();
    }
  }

}
