/*

USAGE
====================
.html_tag{  "data-controller": "modal", 
            "data-title"     : string, 
            "data-content"   : string,
            ["data-modal-id"    : string],
            ["data-modal-class" : string],
            ["data-callbacks" : 
              {
                [beforebuild: string],
                [afterbuild: string],
                [beforedismiss: string],
                [afterdismiss: string]
              }.to_json
            ],
          }


MODAL JST TEMPLATE
====================
app/assets/javascripts/views/modal_frame.jst.ejs


DATA PARAMS
====================

- data-source: string (REQUIRED):
  A string valid as a:
    - DOM Id: 
      Id of the element containing thestuff to be inserted to the modal body (the modal 
      content). Must start with #, as it's how the controller detects the param is either
      an ID or URL.
    - URL:
      An url that returns HTML content to be inserted to the modal body (the modal content).

- data-title: string (REQUIRED):
  A string to be added as the modal title in its header, left of the close button

- data-modal-id: string (optional) (default value: ""):
  A string to be added as element id to the modal container element for scripting purposes

- data-modal-class: string (optional) (default value: ""):
  A string of space separated classes to be added as element classes to the modal container 
  for styling purposes

- data-callbacks: object (optional) (default value: undefined):
  - beforebuild: 
    A string valid as a javascript function to be applied before the modal is built
  - afterbuild: 
    A string valid as a javascript function to be applied after the modal is built
  - beforedismiss: 
    A string valid as a javascript function to be applied before the modal is dismissed
  - afterdismiss: 
    A string valid as a javascript function to be applied after the modal is dismissed

*/

import { Controller } from "@hotwired/stimulus"
import UiHelpers from "../../helpers/ui_helpers.js"

export default class ModalController extends Controller {

  static targets = ["body"];

  initialize() {}

  connect() {
    this.element.dataset.action = "click->modal#build"
    this.callbacks = (this.element.dataset.callbacks ? JSON.parse(this.element.dataset.callbacks) : false)
  }

  build(){
    this.applyCallback("beforebuild")
    this.modal = JST['views/modal_frame']({
      modalId: (this.element.dataset.modalId || ""),
      modalClass: (this.element.dataset.modalClasses || ""),
      title: this.element.dataset.title
    });

    document.body.insertAdjacentHTML('beforeend', this.modal);
    this.container = document.body.lastElementChild
    this.modal = this.container.querySelector(".cpn-modal-container")
    this.modalBody = this.modal.querySelector(".cpn-modal-body")
    this.modalLoading = this.modal.querySelector(".cpn-modal-loading")
    this.bindDismiss()
    
    this.source = this.element.dataset.source
    this.source.charAt(0) == "#" ? this.fromSelector() : this.fromUrl()

    UiHelpers.fadeIn(this.modal)
    this.applyCallback("afterbuild")
  }

  fromSelector(){
    this.content = document.querySelector(this.element.dataset.source)
    this.placeholder = document.createElement("i")
    this.parentNode = this.content.parentNode
    this.parentNode.insertBefore(this.placeholder, this.content)
    this.content = this.modalBody.appendChild(this.content)
  }

  fromUrl(){
    UiHelpers.fadeIn(this.modalLoading)
    $.ajax({ 
      type: 'GET', 
      url: this.source
    }).done( (response) => {
      UiHelpers.fadeOut(this.modalLoading)
      this.content = document.createElement("div")
      this.content.style.opacity = 0
      this.content.insertAdjacentHTML("afterbegin",response)
      this.modalBody.append(this.content)
      UiHelpers.fadeIn(this.content)
    }).fail( () => {
    }).always( () => {
      UiHelpers.fadeOut(this.modalLoading)
    });
  }

  bindDismiss(){
    this.dismissers = this.container.querySelectorAll(".cpn-modal-dismiss")
    this.dismissers.forEach(dismisser => {
      dismisser.addEventListener("click", () => {this.dismiss()} ) 
    })
    
    this.handleEsc = () => {
      if(event.keyCode == 27) this.dismiss()
    }
    window.addEventListener('keydown', this.handleEsc )
  }

  dismiss(){
    this.applyCallback("beforedismiss")
    window.removeEventListener('keydown', this.handleEsc )

    if(this.placeholder != undefined){
      this.parentNode.insertBefore(this.content, this.placeholder)
      this.placeholder.remove()
    }
    UiHelpers.fadeOut(this.modal, () => {this.container.remove()} )
    this.applyCallback("afterdismiss")
  }

  applyCallback(cb){
    if(this.callbacks == false) return
    var callback = (this.callbacks[cb] != undefined ? this.callbacks[cb] : false)
    if(callback == false){
      return
    }else{
      var f = new Function (callback);
      f();
    }
  }

}
