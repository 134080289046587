var UIH = {}

// ======================================================================
// FADE IN/OUT ELEMENT
// UiHelpers.fadeIn(element, callback)
// UiHelpers.fadeOut(element, callback)
// ======================================================================
UIH.fade = function(e,s,f,cb = undefined){
  var i = 0.1, 
      d = "block", 
      c = () => s > f

  e.style.display = d
  e.style.opacity = s

  if(s > f){
    c = () => s < f;
    i = i*(-1)
    d = "none"
  }

  function l(){
    if(c()) {
      e.style.display = d
      e.style.opacity = f
      if(cb != undefined) cb()
    }else{
      s += i
      setTimeout(() => {
        e.style.opacity = s; 
        l() 
      }, 25)
    }
  }
  l()
}

UIH.fadeIn = function(e,cb){
 UIH.fade(e,0,1,cb)
}

UIH.fadeOut = function(e,cb){
 UIH.fade(e,1,0,cb)
} 

UIH.fadeTransition = function(o,n){
  UIH.fade(o,1,0)
  setTimeout( () => {
    UIH.fade(n,0,1)
  },200)
} 

// ======================================================================
// LOADING NOTICE
// UiHelpers.loadingCover(element, extraClasses = "", msg1 = false, msg2 = false){
// ======================================================================
UIH.loadingCover = function(e, extraClasses = "", msg1 = false, msg2 = false){
  var loading = JST['views/utils/loading_cover']({
    noticeClass: extraClasses,
    msg1: msg1,
    msg2: msg2
  })
  
  e.insertAdjacentHTML("afterbegin",loading)
  var o = {}
  o.el = e.firstElementChild
  o.remove = () => { 
    o.el.style.display = "none"
    UIH.fadeOut(o.el, () => {e.removeChild(o.el)})
  }

  o.el.style.opacity = 0

  UIH.fadeIn(o.el)
  return o
}


// ======================================================================
// EXPORT
// ======================================================================
const UiHelpers = UIH
export default UiHelpers